import { POST, GET,downLoad } from './http'

export const getKData = (data)=> GET('k/kdata',data)
export const getKingDay = (data)=> GET('k/day',data)
export const getCardData = (data)=> POST('bank/get',data)
export const getIdCard = (data)=> GET('bank/index',data)
export const getIdCardAdd = (data)=> POST('bank/add',data)
export const getNewsList = (data)=> GET('new/list',data)
export const getNewsDetail = (data)=> GET('new/detail',data)
export const getKings = (data)=> GET('k/index',data)
export const downLoads = imgUrl => downLoad(imgUrl)
export const register = data => POST('login/register', data)
export const login = data => POST('login/login', data)
export const homePair = (data) => GET('product/list',data)
export const getUser = () => GET('user/index')
export const getBalance = () => GET('wallet/index')
export const lpAbout = () => GET('lp/index')
export const lpList = () => GET('lp/list')
export const lpStatistics = () => GET('lp/statistics')
export const authStatus = () => GET('idcert/index')
export const team = () => GET('user/group')
export const currencysList = params => GET('wallet/currencysList', params)
export const productList = params => GET('product/confList', params)
export const contractOrder = params => GET('order/list', params)
export const liquidityOrder = params => GET('lp/orderList', params)
export const historyBill = params => GET('wallet/bill', params)
export const logout = () => GET('login/logout')
export const getBanner = (data) => GET('operate/banner',data)
export const getNotices = () => GET('operate/notice')
export const stake = data => POST('lp/stack', data)
export const addAuth = data => POST('idcert/add', data)
export const sendEmailCode = params => GET('login/sendCode', params)
export const buyContract = data => POST('order/buy', data)
export const withdraw = data => POST('wallet/withdraw', data)
export const updatePwd = data => POST('user/updatePassword', data)
export const cancelLpOrder = data => POST('lp/cancel', data)
export const recharge = data => POST('wallet/recharge', data)
export const uploadFile = data => POST('upload/upload', data, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

export const buyContract2 = data => POST('order_virtual/buy', data)
export const contractOrder2 = params => GET('order_virtual/list', params)

