import { defineStore } from 'pinia';
import { getUser, getBalance, logout, authStatus } from '@/http';
import { showToast } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
import frFR from 'vant/es/locale/lang/fr-FR';
import enES from 'vant/es/locale/lang/es-ES';
import itIT from 'vant/es/locale/lang/it-IT';
import vi from 'vant/es/locale/lang/vi-VN';
import enTH from 'vant/es/locale/lang/th-TH';

export const useUserStore = defineStore('user', {
  state: () => ({
    userinfo: null,
    token: null,
    walletBalance: null,
    symbolData: null,
    lang: 'en_US',
    isAuth: false,
    notice: null,
    /**  @property{Array} tabList = [
         * Gold,金子
         Cryptos,加密货币
         Forex,外汇
         futures 期货] */
    tabList: [
      {
        name: 'Cryptos', // 加密货币
        value: 0,
      },
      //   {
      //     name: 'Gold', //黄金
      //     value: 1,
      //   },
      {
        name: 'Forex', //外汇
        value: 2,
      },
      {
        name: 'Futures', // 期货
        value: 3,
      },
    ],
    localeActions: [
      { name: 'English', value: 'en_US' }, //英语
      { name: 'Français', value: 'fr_FR' }, //法语
      { name: 'Español', value: 'es_ES' }, //西班牙语
      { name: 'Italiano', value: 'it_IT' }, //意大利语
      // {name: 'Tiếng Việt', value: 'vi'}, //越南语
      //   {name: 'ภาษาไทย', value: 'en_TH'}, //泰语
    ],
    langMapSet: {
      en_US: {
        value: enUS,
        key: 'en-US',
      },
      fr_FR: {
        value: frFR,
        key: 'fr-FR',
      },
      es_ES: {
        value: enES,
        key: 'es-ES',
      },
      it_IT: {
        value: itIT,
        key: 'it-IT',
      },
      vi: {
        value: vi,
        key: 'vi',
      },
      en_TH: {
        value: enTH,
        key: 'en_TH',
      },
    },
  }),
  actions: {
    setNotice(data) {
      this.notice = data;
    },
    setLang(data) {
      this.lang = data;
    },
    setSymbolData(data) {
      this.symbolData = data;
    },
    setUserinfo(data) {
      this.userinfo = data;
    },
    setToken(data) {
      this.token = data;
    },
    updateUser() {
      return new Promise((resolve, reject) => {
        getUser()
          .then(({ data }) => {
            this.userinfo = data;
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getBalance() {
      return new Promise((resolve, reject) => {
        getBalance()
          .then(({ data }) => {
            this.walletBalance = data;
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    logout() {
      this.userinfo = null;
      this.token = null;
      this.walletBalance = null;
      logout().then(() => {
        showToast('退出成功');
      });
    },
    getAuthState() {
      authStatus().then(({ data }) => {
        this.isAuth = data === 1;
      });
    },
  },
  persist: true,
});
